import React from 'react'

const PmsLink = () => {
  return (
     <>
     
          <div
            className="text-center md:py-10 py-6 space-y-4 md:px-0 px-5"
            data-aos="zoom-out"
            data-aos-delay="600"
            data-aos-duration="1000"
          >
            {/* <div>
            
          <img src={leftshawdo} alt="" className="-mt-[52rem] absolute md:top-[45rem] -left-[4rem] w-fit z-10 opacity-100"/>
    </div> */}
    
            <h3 className="text-4xl text-blue-950">Investor-Information</h3>
            <p className="text-lg font-medium">
            Investor Charter link as per regulatory guideline -<a
                      href="#" target="_blank"
                      className="px-2 py-2 font-semibold text-blue-900 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >"Investor Charter"</a>
            </p>
            <div className="py-6 md:mx-44 mx-2 text-left space-y-2">
                <p className='ps-4'>Disclosure :</p>
                <p><a
                      href="#" target="_blank"
                      className="px-4 py-2  text-blue-900 data-[focus]:bg-gray-100 font-semibold data-[focus]:text-gray-900"
                    >PMS Disclosure Document</a></p>
                <p><a
                      href="#" target="_blank"
                      className="px-4 py-2  text-blue-900 data-[focus]:bg-gray-100 font-semibold data-[focus]:text-gray-900"
                    >Fee Calculation Tool</a></p>
            </div>
            <div className='md:mx-48 mx-2 text-left'>
                <p>The data pertaining to all complaints including SCORES complaints received as per the format mentioned in regulatory communication.</p>
            </div>

            <div className='md:mx-40 px-4 mx-2 text-left py-5'>
            <p><a
                      href="#" target="_blank"
                      className="md:px-4 px-0 py-2  text-blue-900 data-[focus]:bg-gray-100 font-semibold data-[focus]:text-gray-900"
                    >Complaints received till November 2024</a></p>
            </div>
            <div className='md:mx-44 md:px-4 px-2 mx-2 text-left py-3'>
            <p>For any other queries or assistance kindly <a
                      href="#" target="_blank"
                      className=" py-2  text-blue-900 data-[focus]:bg-gray-100 font-semibold data-[focus]:text-gray-900"
                    >click here</a> to connect with us.</p>
            </div>
            <div className='md:mx-44 md:px-4 px-2 mx-2  text-left py-3'>
            <p>Document link for <a
                      href="#" target="_blank"
                      className=" py-2  text-blue-900 data-[focus]:bg-gray-100 font-semibold data-[focus]:text-gray-900"
                    >SOP for Centralized mechanism for reporting the demise of an investor</a> </p>
            </div>
          </div>

          
          
        </>
  )
}

export default PmsLink
